<template>
    <v-row>
      <v-col>
        <v-card flat>
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-bold">Configuración de POS</v-toolbar-title>
          </v-toolbar>
          <v-divider />
          <v-card-text class="px-5 py-4">
            <PermissionDenied v-if="!$helpers.hasPermission(['view_pos'])" :isDetail="true" />
            <template v-else>
              <v-skeleton-loader v-if="loadingConfig" transition="fade-transition" type="list-item-three-line" />
              <template v-else>
                <v-row no-gutters v-for="(config, index) in configList" :key="index">
                  <v-col cols="4">
                    <v-list class="v-list-form-extended transparent pa-0">
                      <v-list-item class="pa-0">
                        <v-list-item-title>Nombre</v-list-item-title>
                        <v-list-item-subtitle>{{ config.name }}</v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="5">
                    <v-list class="v-list-form-extended transparent pa-0">
                      <v-list-item class="pa-0">
                        <v-list-item-title>Creado el</v-list-item-title>
                        <v-list-item-subtitle>{{ config.created | dateTimeSecond }}</v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col class="px-0">
                    <v-list class="v-list-form-extended transparent pa-0">
                      <v-list-item class="pa-0 blue-500--text body-2" @click="handlerViewConfig(config)">Ver configuración</v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
                <v-col class="pa-0 pt-1">
                  <v-btn @click="dialogGroup=true" text color="blue-500" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
                    <span class="font-weight-medium body-1">Agregar otro grupo de configuración</span>
                  </v-btn>
                </v-col>
              </template>
            </template>
          </v-card-text>
        </v-card>
        <v-card class="mt-5 d-none" flat>
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-bold">Envío de LOGS</v-toolbar-title>
          </v-toolbar>
          <v-divider />
          <v-card-text class="px-5 py-4">
            <v-skeleton-loader v-if="loadingEmails" transition="fade-transition" type="list-item-three-line" />
            <v-list class="v-list-form-extended transparent pa-0" v-else>
              <v-list-item class="pa-0">
                <v-list-item-title>Envio de LOGS por mail</v-list-item-title>
                <v-list-item-subtitle><v-switch class="mt-n4" v-model="disable_sending" color="green-500" dense style="position:absolute" :ripple="false" /></v-list-item-subtitle>
              </v-list-item>
              <v-list-item class="my-n4" v-for="(notification, i) in instance.emails" :key="notification">
                <v-list-item-title>Correo de notificación</v-list-item-title>
                <v-list-item-subtitle class="d-flex align-center">
                  <v-row align="center" no-gutters>
                    <div style="width: 180px;">
                      <span class="d-inline-block text-truncate" style="max-width: 180px;">{{notification}}</span>
                    </div>
                    <v-list-item-action>
                      <v-btn @click="instance.emails.splice(i, 1)" icon color="grey-700" :ripple="false"><v-icon>mdi-delete</v-icon></v-btn>
                    </v-list-item-action>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-for="(d, i) in emailsList" :key="i" class="pa-0">
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>
                  <v-row class="ma-0" no-gutters align="center">
                    <v-col class="py-1 pr-2" cols="8">
                      <v-text-field v-model.trim="$v.emailsList.$each[i].email.$model" :error="$v.emailsList.$each[i].email.$error" hide-details maxlength="254" outlined single-line dense />
                    </v-col>
                    <v-list-item-action class="ma-0">
                      <v-btn v-if="i >= 1 || emailsList.length > 1" @click="emailsList.splice(i, 1)" icon color="red" :ripple="false"><v-icon small>mdi-close</v-icon></v-btn>
                    </v-list-item-action>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item>
              <v-col class="pa-0 pt-1">
                <v-btn v-if="(emailsList.length + ((instance || {}).emails || []).length) < 8" @click.prevent="addRowEmails" text color="blue-500" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
                  <span class="font-weight-medium body-1">Agregar otro mail</span>
                </v-btn>
              </v-col>
            </v-list>
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-5 py-4">
            <v-spacer />
            <v-btn class="px-4" @click="handlerCancel" outlined>Cancelar</v-btn>
            <v-btn class="px-4" @click="Object.prototype.hasOwnProperty.call(instance, 'xml_store_path') ? updatedEmails() : sendEmails()" color="blue-500" :loading="loading">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- dialog config group -->
      <v-dialog v-model="dialogGroup" width="700" persistent no-click-animation overlay-color="grey-500" scrollable>
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar flat height="46" class="transparent">
              <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">{{modeView || isClone ? 'Configuración POS' : 'Agregar otro'}}</v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-row align="center" justify="center" class="mr-3">
                  <v-btn class="ml-3" @click="dialogGroup=false, clearInput()" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
                </v-row>
              </v-toolbar-items>
            </v-toolbar>
          </v-card-title>
          <v-divider />
          <v-card-text class="py-4 px-0" id="container" @scroll="onScroll">
            <div>
              <v-col class="py-0 px-5">
                <span class="grey-500--text body-1">Nombre</span>
                <v-text-field v-model="$v.config.name.$model" :error="$v.config.name.$error" :readonly="modeView" required outlined single-line dense hide-details />
              </v-col>
              <v-divider class="my-5" />
              <span class="px-5 pb-5 d-block font-weight-semibold grey-700--text subtitle-2">Tipo de documento</span>
              <v-row :class="[`px-5 pb-2`, {'pb-0' : !modeView }]" v-for="(d, i) in documentTypeList" :key="i" no-gutters>
                <v-col :cols="documentTypeList.length > 0 ? '25%' : 6" class="pa-0 pr-4">
                  <span class="grey-500--text body-1">Tipo de documento</span>
                  <v-autocomplete v-model="$v.documentTypeList.$each[i].type.$model" :error="$v.documentTypeList.$each[i].type.$error" append-icon="mdi-chevron-down" :readonly="modeView" label="Selecciona un tipo de documento" :items="documentTypes.filter(({country}) => country === $store?.state?.auth?.account?.country)" item-value="id" item-text="name" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details>
                    <template v-slot:item="data">
                      <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="3" class="pa-0 pr-2">
                  <span class="grey-500--text body-1">Cantidad</span>
                  <v-text-field v-model="$v.documentTypeList.$each[i].quantities.$model" :error="$v.documentTypeList.$each[i].quantities.$error" :readonly="modeView" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" outlined required single-line dense maxlength="100" hide-details />
                </v-col>
                <v-col cols="3" class="pa-0 pl-2">
                  <span class="grey-500--text body-1">Umbral</span>
                  <v-text-field v-model="$v.documentTypeList.$each[i].thresholds.$model" :error="$v.documentTypeList.$each[i].thresholds.$error" :readonly="modeView" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" outlined required single-line dense maxlength="100" hide-details />
                </v-col>
                <v-list-item-action style="min-width: 5%;" v-if="!modeView">
                  <v-btn class="ml-4 mt-1" @click="documentTypeList.splice(i, 1)" text color="grey-700" :ripple="false"><v-icon small>mdi-delete</v-icon></v-btn>
                </v-list-item-action>
              </v-row>
              <v-col class="pt-2 pb-2 mt-n5" v-if="!modeView">
                <v-btn @click.prevent="addRowdDcumentTypeList" text color="blue-500" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
                  <span class="font-weight-medium body-1">Agregar tipo de documento</span>
                </v-btn>
              </v-col>
              <v-divider />
              <v-row class="px-5 pt-5 pb-2" no-gutters>
                <v-col cols="6" class="pa-0 pr-4">
                  <span class="grey-500--text body-1">Tamaño de lote</span>
                  <v-text-field v-model="$v.config.batch_size.$model" :error="$v.config.batch_size.$error" :readonly="modeView" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" required outlined single-line dense hide-details />
                </v-col>
                <v-col cols="3" class="pa-0 pr-2">
                  <span class="grey-500--text body-1">Timeout de lote</span>
                  <v-text-field v-model="$v.config.batch_timeout.$model" :error="$v.config.batch_timeout.$error" :readonly="modeView" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" outlined required single-line dense maxlength="100" hide-details />
                </v-col>
                <v-col cols="3" class="pa-0 pl-2">
                  <span class="grey-500--text body-1">Intervalo de lote</span>
                  <v-text-field v-model="$v.config.batch_interval.$model" :error="$v.config.batch_interval.$error" :readonly="modeView" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" outlined required single-line dense maxlength="100" hide-details />
                </v-col>
              </v-row>
              <v-divider class="my-4" />
              <v-col class="py-0 px-5">
                <span class="grey-500--text body-1">Timeout de certificado</span>
                <v-text-field v-model="$v.config.cert_timeout.$model" :error="$v.config.cert_timeout.$error" :readonly="modeView" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" required outlined single-line dense hide-details />
              </v-col>
              <v-divider class="my-4" />
              <v-col class="py-0 px-5">
                <span class="grey-500--text body-1">Timeout de configuración</span>
                <v-text-field v-model="$v.config.conf_timeout.$model" :error="$v.config.conf_timeout.$error" :readonly="modeView" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" required outlined single-line dense hide-details />
              </v-col>
              <v-divider class="my-5" />
              <span class="px-5 pb-5 d-block font-weight-semibold grey-700--text subtitle-2">Mensajes de reintento</span>
              <v-row class="px-5 pb-6" no-gutters>
                <v-col cols="6" class="pa-0 pr-2">
                  <span class="grey-500--text body-1">Intervalo reitento</span>
                  <v-text-field v-model="$v.config.msgretry_interval.$model" :error="$v.config.msgretry_interval.$error" placeholder="Segundos" :readonly="modeView" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" outlined required single-line dense maxlength="100" hide-details />
                </v-col>
                <v-col cols="6" class="pa-0 pl-2">
                  <span class="grey-500--text body-1">Timeout reintento</span>
                  <v-text-field v-model="$v.config.msgretry_timeout.$model" :error="$v.config.msgretry_timeout.$error" placeholder="Segundos" :readonly="modeView" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" outlined required single-line dense maxlength="100" hide-details />
                </v-col>
              </v-row>
              <v-divider />
              <span class="px-5 pt-5 d-block font-weight-semibold grey-700--text subtitle-2">Mensajes de señal de vida</span>
              <v-row class="px-5 pt-5 pb-2" no-gutters>
                <v-col cols="6" class="pa-0 pr-2">
                  <span class="grey-500--text body-1">Intervalo de señal</span>
                  <v-text-field v-model="$v.config.signal_interval.$model" :error="$v.config.signal_interval.$error" placeholder="Segundos" :readonly="modeView" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" required outlined single-line dense hide-details />
                </v-col>
                <v-col cols="6" class="pa-0 pl-2">
                  <span class="grey-500--text body-1">Timeout de señal</span>
                  <v-text-field v-model="$v.config.signal_timeout.$model" :error="$v.config.signal_timeout.$error" placeholder="Segundos" :readonly="modeView" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" outlined required single-line dense maxlength="100" hide-details />
                </v-col>
              </v-row>
              <v-divider class="my-5" />
              <v-col cols="6" class="pt-0 px-5">
                <span class="grey-500--text body-1">Generación PDF</span>
                <v-select v-model="$v.config.gen_pdf.$model" :error="$v.config.gen_pdf.$error" :items="genPdfList" item-text="text" item-value="value" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" :readonly="modeView" height="30" single-line outlined dense hide-details />
              </v-col>
              <v-col cols="6" class="pt-0 px-5">
                <span class="grey-500--text body-1">Codificación de entrada</span>
                <v-select v-model="config.input_charset" :items="inputEncodingList" item-text="text" item-value="value" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" :readonly="modeView" height="30" single-line outlined dense hide-details />
              </v-col>
              <v-col class="py-0 my-3">
                <v-list class="v-list-form-extended transparent pa-0">
                <v-list-item class="pl-2">
                  <v-list-item-title>Carpeta compartida</v-list-item-title>
                    <v-list-item-subtitle><v-switch class="mt-n4" v-model="$v.config.shared_folder_enable.$model" :error="$v.config.shared_folder_enable.$error" :readonly="modeView" color="green-500" dense style="position:absolute" :ripple="false" /></v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col class="py-0">
                <v-list class="v-list-form-extended transparent pa-0">
                <v-list-item class="pl-2">
                  <v-list-item-title>Auto confirmación</v-list-item-title>
                    <v-list-item-subtitle><v-switch class="mt-n4" v-model="$v.config.doc_auto_confirm.$model" :error="$v.config.doc_auto_confirm.$error" :readonly="modeView" color="green-500" dense style="position:absolute" :ripple="false" /></v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-col>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-5 py-2">
            <v-spacer/>
            <v-btn outlined @click="dialogGroup=false, clearInput()">Cancelar</v-btn>
            <v-btn color="blue-500" :loading="loadingCreateConfig" @click="!modeView ? postConfig() : handlerClone()" :disabled="!modeView && enabledBtn">{{!modeView ? 'Guardar' : 'Duplicar'}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog config group -->
    </v-row>
  </template>
<script>
import { email, maxLength, required } from 'vuelidate/lib/validators'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import cloneDeep from 'lodash/cloneDeep'
import DocumentTypes from '@/collections/documentTypes'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  mixins: [
    GenericViewMixin
  ],
  components: {PermissionDenied},
  data: () => ({
    inputEncodingList: ['Vacío', 'UTF-8', 'ISO-8859-1'],
    genPdfList: [{
        text: 'No',
        value: 'NON'
      },
      {
      text: 'Antes del envío',
      value: 'GEN'
      },
      {
      text: 'Después del envío',
      value: 'SND'
    }],
    scroll: 0,
    documentTypes: DocumentTypes,
    config: {
      name: '',
      signal_interval: '',
      signal_timeout: '',
      batch_size: '',
      batch_timeout: '',
      batch_interval: '',
      cert_timeout: '',
      conf_timeout: '',
      msgretry_interval: '',
      msgretry_timeout: '',
      doc_auto_confirm: false,
      shared_folder_enable: false,
      gen_pdf: '',
      input_charset: ''
    },
    configList: [],
    dialogGroup: false,
    loadingConfig: false,
    loadingCreateConfig: false,
    disable_sending: null,
    loadingEmails: false,
    loading: false,
    instance: [],
    documentTypeList: [],
    emailsList: [{email: ''}],
    enabledBtn: true,
    modeView: false,
    isClone: true
  }),
  created () {
    // this.getEmails()
    this.getConfig()
  },
  methods: {
    onScroll (e) {
      const div = event.target
      this.enabledBtn = !(div.scrollHeight - div.scrollTop === div.clientHeight)
    },
    handlerViewConfig (item = {}) {
      this.isClone = false
      this.modeView = true
      this.config = {
        name: item.name,
        signal_interval: item.signal_interval,
        signal_timeout: item.signal_timeout,
        batch_size: item.batch_size,
        batch_timeout: item.batch_timeout,
        batch_interval: item.batch_interval,
        cert_timeout: item.cert_timeout,
        conf_timeout: item.conf_timeout,
        msgretry_interval: item.msgretry_interval,
        msgretry_timeout: item.msgretry_timeout,
        doc_auto_confirm: item.doc_auto_confirm,
        shared_folder_enable: item.shared_folder_enable,
        gen_pdf: item.gen_pdf,
        input_charset: item.input_charset
      }
      this.documentTypeList = []
      let thresholds = []
      Object.entries(item.thresholds).forEach(([k, v]) => {
        thresholds.push({ type: k, values: v})
      })
      Object.entries(item.quantities).forEach(([key, value]) => {
        this.documentTypeList.push({type: key, quantities: value, thresholds: thresholds.find(({type}) => type === key).values})
      })
      this.dialogGroup = true
    },
    handlerClone () {
      this.modeView = false
      this.config.name = ''
      this.isClone = true
    },
    getConfig () {
      this.loadingConfig = true
      this.$store.dispatch('pos/LIST', {
        resource: 'pos-app/configurations'
      })
      .then((response) => {
        this.configList = response.data
      })
      .finally(() => {
        this.loadingConfig = false
      })
    },
    postConfig () {
      this.$v.config.$touch()
      this.$v.documentTypeList.$touch()
      if (this.$v.documentTypeList.$invalid || this.$v.config.$invalid) {
        return false
      }

      this.loadingCreateConfig = true
      let quantities = {}
      let thresholds = {}
      this.documentTypeList.forEach((item) => {
        if (Object.keys(quantities).includes(item.type)) {
          quantities[item.type] += parseInt(item.quantities)
        } else {
          quantities = {
            ...quantities,
            [item.type]: parseInt(item.quantities)
          }
        }
        if (Object.keys(thresholds).includes(item.type)) {
          thresholds[item.type] += parseInt(item.thresholds)
        } else {
          thresholds = {
            ...thresholds,
            [item.type]: parseInt(item.thresholds)
          }
        }
      })

      if (this.config.input_charset === 'Vacío') delete this.config.input_charset

      this.$store.dispatch('pos/CREATE', {
        resource: `pos-app/configurations`,
        payload: {
          ...this.config,
          quantities: quantities,
          thresholds: thresholds
        }
      })
      .then(() => {
        this.$dialog.message.info('La configuración se ha creado con éxito')
        this.getConfig()
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
      .finally(() => {
        this.loadingCreateConfig = false
        this.dialogGroup = false
        this.clearInput()
        this.isClone = false
      })
    },
    clearInput () {
      this.$v.config.$reset()
      this.config = {
        signal_interval: '',
        signal_timeout: '',
        batch_size: '',
        batch_timeout: '',
        batch_interval: '',
        cert_timeout: '',
        conf_timeout: '',
        msgretry_interval: '',
        msgretry_timeout: '',
        doc_auto_confirm: false,
        shared_folder_enable: false,
        gen_pdf: '',
        input_charset: ''
      }
      this.documentTypeList = [{type: '', quantities: '', thresholds: ''}]
      this.$v.documentTypeList.$reset()
      this.modeView = false
    },
    getEmails () {
      this.loadingEmails = true
      this.$store.dispatch('pos/RETRIEVE', {
        resource: 'pos/settings',
        id: this.$store.state.auth.account.acc_id
      })
      .then((response) => {
        this.disable_sending = response.data.disable_sending
        this.instance = cloneDeep(response.data)
      })
      .finally(() => {
        this.loadingEmails = false
      })
    },
    addRowEmails () {
      this.emailsList.push({email: ''})
    },
    addRowdDcumentTypeList () {
      this.documentTypeList.push({type: '', quantities: '', thresholds: ''})
    },
    sendEmails () {
      this.$v.emailsList.$touch()
      if (this.$v.emailsList.$invalid) {
        return false
      }
      let list = [...new Set([...this.emailsList.map((item) => item.email)])]
      this.loading = true
      this.$store.dispatch('pos/CREATE', {
        resource: `pos/settings`,
        payload: {
          emails: list.filter((e) => e.length),
          disable_sending: this.disable_sending ? 'True' : 'False'
        }
      })
      .then(() => {
        this.$dialog.message.info('El envío de LOG se realizó con éxito')
        list = []
        this.reset()
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
      .finally(() => {
        this.loading = false
      })
    },
    updatedEmails () {
      this.$v.emailsList.$touch()
      if (this.$v.emailsList.$invalid) {
        return false
      }
      this.loading = true
      let list = [...new Set([...this.instance.emails, ...this.emailsList?.map((item) => item.email)])]
      this.$store.dispatch('pos/UPDATE', {
        resource: `pos/settings`,
        id: this.$store.state.auth.account.acc_id,
        payload: {
          emails: list.filter((e) => e?.length),
          disable_sending: this.disable_sending ? 'True' : 'False'
        }
      })
      .then(() => {
        this.$dialog.message.info('El envío de LOG se realizó con éxito')
        list = []
        this.reset()
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
      .finally(() => {
        this.loading = false
      })
    },
    handlerCancel () {
      this.emailsList = [[{email: ''}]]
      if (this.instance.emails.length) this.getEmails()
    },
    reset () {
      this.emailsList = [[{email: ''}]]
      this.getEmails()
      this.$v.emailsList.$reset()
    }
  },
  validations: {
    emailsList: {
      $each: {
        email: {
          email,
          maxLength: maxLength(254)
        }
      }
    },
    config: {
      name: {
        required
      },
      // quantities: {
      //   required
      // },
      // thresholds: {
      //   required
      // },
      signal_interval: {
        required
      },
      signal_timeout: {
        required
      },
      batch_size: {
        required
      },
      batch_timeout: {
        required
      },
      batch_interval: {
        required
      },
      cert_timeout: {
        required
      },
      conf_timeout: {
        required
      },
      msgretry_interval: {
        required
      },
      msgretry_timeout: {
        required
      },
      doc_auto_confirm: {
        required
      },
      shared_folder_enable: {
        required
      },
      gen_pdf: {
        required
      }
    },
    documentTypeList: {
      $each: {
        type: {
          required
        },
        quantities: {
          required
        },
        thresholds: {
          required
        }
      }
    }
  }
}
</script>
<style>
#container {
  overflow-y: auto;
  height: 700px;
}
</style>